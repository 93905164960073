<template>
	<div
		class="modal fade"
		id="addRouteModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="topupWalletLabel"
		aria-hidden="true"
		>
		<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="topupWalletLabel">Book a trip</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="book">
						<div class="row">
							<div class="col-12">
								<label class="form-label">Select Route</label>
								<v-select
									v-model="form.route"
									class="form-group"
									:options="routes"
									label="route_code"
									required
									>
									<template v-slot:option="route">
										<span v-if="route.route_code">{{
											`${route.route_code} - `
										}}</span>
										From {{ route.pickup }} To {{ route.destination }}
									</template>
									<template v-slot:selected-option="route">
										<span v-if="route.route_code">{{
											`${route.route_code} - `
										}}</span>
										From {{ route.pickup }} To {{ route.destination }}
									</template>
								</v-select>
							</div>
						</div>

						<div class="row mb-4" v-if="form.route">
							<div class="col-12 col-md-6">
								<div class="text-muted text-uppercase">From</div>
								<div>{{ form.route.pickup }}</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="text-muted text-uppercase">To</div>
								<div>{{ form.route.destination }}</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<label class="form-label">Select Itinerary</label>
								<v-select
									v-model="form.itinerary"
									class="form-group"
									:options="itineraries"
									label="trip_time"
									required
									>
									<template v-slot:option="itinerary">
										{{ itinerary.trip_time }}
									</template>
									<template v-slot:selected-option="itinerary">
										{{ itinerary.trip_time }}
									</template>
								</v-select>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label class="form-label">Select Pickup Point</label>
									<v-select
										v-model="form.pickup"
										class="form-group"
										:options="pickupLocations"
										label="name"
										required
										>
									</v-select>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label class="form-label">Select Drop-off Point</label>
									<v-select
										v-model="form.destination"
										class="form-group"
										:options="destinations"
										label="name"
										required
										>
									</v-select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label class="form-label">Choose Date</label>
									<div style="width: 100%">
										<v-datepicker
											v-model="form.startDate"
											placeholder="Filter by date"
											style="width: 100%"
											></v-datepicker>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="description" class="col-form-label"
										>Select Payment Source</label
										>
									<select
										class="form-select form-control mb-3"
										v-model.trim="form.source"
										required
										>
										<option value="main_balance">Main Balance</option>
										<option value="credit_balance">Company Balance</option>
                    <option value="instant_payment">Charge my company</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<div class="form-group d-inline-flex">
									<label>Is Subscription?</label>
									<div class="form-check form-switch ml-2">
										<input
											v-model="form.isSubscription"
											class="form-check-input"
											id="switchOne"
											type="checkbox"
											/>
										<label class="form-check-label" for="switchOne"></label>
									</div>
								</div>
							</div>
						</div>
						<template v-if="form.isSubscription">
							<div class="mb-4">
								<label>Select Pickup Days</label>
								<div class="">
									<div
										class="form-check"
										v-for="(day, index) in Object.keys(dayWithIds)"
										:key="index"
										>
										<input
											v-model="form.subscriptionDays"
											class="form-check-input"
											type="checkbox"
											:id="day"
											:value="day"
											/>
										<label :for="day" class="form-check-label">{{
											day | titleCase
										}}</label>
									</div>
								</div>
							</div>
							<div class="mb-4">
								<label>Select Trip Weeks</label>
								<div class="">
									<div
										class="form-radio"
										v-for="item in subscriptionWeeks"
										:key="item.value"
										>
										<input
											v-model="form.tripWeeks"
											class="form-radio-input"
											type="radio"
											:id="item.value"
											:value="item.value"
											/>
										<label :for="item.value" class="form-radio-label ml-2">{{
											item.label
										}}</label>
									</div>
								</div>
							</div>
						</template>
						<div class="row">
							<div class="col-12">
								<div class="form-group d-inline-flex">
									<label>With Luggage?</label>
									<div class="form-check form-switch ml-2">
										<input
											v-model="form.withLuggage"
											class="form-check-input"
											id="switchTwo"
											type="checkbox"
											/>
										<label class="form-check-label" for="switchTwo"></label>
									</div>
								</div>
							</div>
						</div>
						<div class="row" v-if="form.withLuggage">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label class="form-label">Luggage Quantity</label>
									<input
										v-model.trim="form.luggageQuantity"
										type="number"
										class="form-control mb-3"
										inputmode="number"
										/>
								</div>
							</div>
						</div>
						<div class="row" v-if="form.destination">
							<div class="col-12">
								<div class="form-group d-inline-flex">
									<label>Fare:</label>
									<div class="ml-2 font-weight-bold" v-if="!errorFetchingPrice">
										₦{{ totalFare.fare | money }}
									</div>
									<span v-else>N/A</span>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						:disabled="processing || errorFetchingPrice"
						@click="book"
						>
						{{ processing ? 'Processing...' : 'Confirm and Book Ride' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#addRouteModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'
import { addWeeks, subDays, getDay, format } from 'date-fns'
import FeatureFlagService, { FEATURE_FLAGS } from '@/utils/feature-flags'
import drpService from '@/views/Routes/itenerary/drp.service'
import { extractErrorMessage } from '@/utils/helpers'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    show: Boolean,
    entity: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        route: null,
        pickup: null,
        destination: null,
        itineraryId: null,
        itinerary: null,
        startDate: '',
        source: 'main_balance',
        isSubscription: false,
        withLuggage: false,
        subscriptionDays: [],
        tripWeeks: 0,
        luggageQuantity: 0
      },
      dayWithIds: {
        sunday: 1,
        monday: 2,
        tuesday: 3,
        wednesday: 4,
        thursday: 5,
        friday: 6,
        saturday: 7
      },
      subscriptionWeeks: [
        { label: '1 Week', value: 1 },
        { label: '2 Weeks', value: 2 },
        { label: '3 Weeks', value: 3 },
        { label: '4 Weeks', value: 4 }
      ],
      processing: false,
      errorMessage: '',
      routes: [],
      destinationPoints: [],
      busStops: [], // TODO: memoize
      itineraries: [], // TODO: memoize
      itineraryPriceMap: new Map(),
      fetchingFare: false,
      fare: null,
      fetchingItineraryBusStops: false,
      itineraryBusStops: [],
      errorFetchingPrice: false
    }
  },
  created () {
    this.fetchRoutes()
  },
  beforeDestroy () {
    this.itineraryPriceMap.clear()
  },
  watch: {
    'form.route' (value) {
      if (value) {
        this.form.itinerary = null
        this.fetchRouteItineraries()
      }
    },
    'form.itinerary' (value) {
      if (value) {
        this.fetchItineraryBusStops()
      }
      this.busStops = []
      this.form.pickup = null
      this.form.destination = null
    },
    'form.pickup' () {
      this.getDestinationPoints()
    },
    'form.isSubscription' (value) {
      if (!value) {
        this.form.subscriptionDays = []
        this.form.tripWeeks = 0
      }
    },
    busStopsSelection: {
      immediate: true,
      handler: async function (value) {
        if (this.form.itinerary) {
          if (this.form.pickup && this.form.destination) {
            const fare = await this.getTripFare(
              this.form.itinerary,
              this.form.pickup,
              this.form.destination
            )
            if (fare !== null) {
              this.fare = fare
            } else if (this.isNewDRPEnabled) {
              this.$toastr.e('Unable to retrieve trip fare')
            } else {
              this.fare = this.form.itinerary?.default_fare
            }
          }
        }
      }
    }
  },
  computed: {
    destinations () {
      return this.destinationPoints
    },
    isNewDRPEnabled () {
      return FeatureFlagService.isFlagEnabled(FEATURE_FLAGS.DRP_CLIENT)
    },
    pickupLocations () {
      return this.busStops.filter((stop) => stop.is_pickup)
    },
    busStopsSelection () {
      return `${this.form.pickup?.id}_${this.form.destination?.id}`
    },
    tripFare () {
      if (this.form.itinerary) {
        return {
          fare: this.isNewDRPEnabled
            ? this.fare || 0
            : this.fare != null
              ? this.fare
              : this.form.itinerary?.default_fare,
          currency: this.form.itinerary?.default_fare_currency
        }
      }
      return { fare: this.fare || 0 }
    },
    totalFare () {
      let totalFare
      if (this.form.isSubscription && this.form.subscriptionDays.length > 0) {
        totalFare =
          this.tripFare.fare *
          this.form.subscriptionDays.length *
          parseInt(this.form.tripWeeks)
      } else {
        totalFare = this.tripFare.fare
      }
      return {
        fare: totalFare || 0,
        currency: this.tripFare?.currency || 'NGN'
      }
    },
    endDate () {
      if (this.form.isSubscription && this.form.tripWeeks) {
        const _date = addWeeks(new Date(this.form.startDate), this.form.tripWeeks)

        return format(subDays(_date, 1), 'yyyy-MM-dd')
      }

      return format(this.form.startDate, 'yyyy-MM-dd')
    }
  },
  methods: {
    fetchRoutes () {
      this.axios
        .get('/v1/routes', {
          params: {
            'fields[route]': 'id,pickup,destination,status,route_code',
            related: '',
            status: 1,
            limit: 1000000
          }
        })
        .then((res) => {
          this.routes = res.data
        })
    },
    fetchItineraryBusStops () {
      this.fetchingItineraryBusStops = true
      return this.axios
        .get(`/v1/itineraries/${this.form.itinerary.id}/busstops`)
        .then((res) => {
          this.busStops = res.data.data
        })
        .finally(() => (this.fetchingItineraryBusStops = false))
    },
    fetchRouteItineraries () {
      return this.$axios
        .get(`/v1/routes/${this.form.route.id}/itineraries?itinerary_only=true`)
        .then((res) => {
          this.itineraries = res.data.data
          if (this.itineraries.length === 1) {
            this.form.itinerary = this.itineraries[0]
          }
        })
    },
    async getTripFare (itinerary, startStopData, endStopData) {
      const isNewDRPEnabled = this.isNewDRPEnabled
      let tripFare = !isNewDRPEnabled ? itinerary.default_fare : null
      const key = `${itinerary.id}_${startStopData.id}_${endStopData.id}`
      this.errorFetchingPrice = false
      if (this.itineraryPriceMap.has(key)) {
        tripFare = this.itineraryPriceMap.get(key)
        return tripFare
      }
      this.fetchingFare = true
      try {
        const request = isNewDRPEnabled
          ? this.axios.post('/drp/v1/pricing/pricing-calculator', {
            itineraryId: itinerary.id,
            coordinates: [
              { ...drpService.convertXYtoLatLng(startStopData.geometry) },
              { ...drpService.convertXYtoLatLng(endStopData.geometry) }
            ],
            startStopId: startStopData.id,
            endStopId: endStopData.id
          })
          : this.axios.post(
              `/v1/routes/${itinerary.route_id}/price-calculation`,
              {
                pickup_id: startStopData.id,
                destination_id: endStopData.id
              }
          )
        const res = await request
        const priceData = res.data
        if (isNewDRPEnabled) {
          if (priceData.data) {
            this.itineraryPriceMap.set(
              `${priceData.data.itinerary_id}_${startStopData.id}_${endStopData.id}`,
              priceData.data.fare
            )
            tripFare = priceData.data.fare
          }
        } else {
          if (priceData && priceData.length) {
            priceData.forEach((itineraryPrice) => {
              const { fare, itinerary_id } = itineraryPrice
              if (itinerary_id === itinerary.id) {
                tripFare = fare
              }
              this.itineraryPriceMap.set(
                `${itinerary_id}_${startStopData.id}_${endStopData.id}`,
                fare
              )
            })
          }
        }
      } catch (e) {
        this.$toastr.e(extractErrorMessage(e, 'Unable to fetch trip fare'))
        this.errorFetchingPrice = true
      } finally {
        this.fetchingFare = false
      }
      return tripFare
    },
    getDestinationPoints () {
      this.form.destination = null
      this.destinationPoints = this.busStops.filter(
        (stop) =>
          stop.is_dropoff &&
          (this.form.pickup ? stop.position > this.form.pickup.position : false)
      )
    },
    book () {
      let dayIds = []

      if (!this.form.subscriptionDays.length) {
        dayIds.push(getDay(new Date(this.form.startDate)) + 1)
      } else {
        dayIds = this.form.subscriptionDays.map((day) => this.dayWithIds[day])
      }

      let postData = {
        route_id: this.form.route.id,
        itinerary_id: this.form.itinerary.id,
        pickup_id: this.form.pickup.id,
        destination_id: this.form.destination.id,
        days_ids: dayIds,
        meta: JSON.stringify(this.form.route),
        start_date: format(this.form.startDate, 'yyyy-MM-dd'),
        end_date: this.endDate,
        recurring: this.form.isSubscription ? 1 : 0,
        payment_source: this.form.source
      }
      if (this.form.source === 'instant_payment') {
        postData.payment_reference = uuidv4()
        postData.instant_payment_provider = 'corporate_pay'
      }

      if (this.form.withLuggage) {
        postData.luggage_quantity = this.form.withLuggage
          ? this.form.luggageQuantity
          : 0
      }
      if (this.isNewDRPEnabled) {
        postData = { ...postData, differential_price: this.fare }
      }
      this.processing = true
      this.errorMessage = ''
      this.axios
        .post(`/v1/users/${this.entity.id}/routes`, postData)
        .then(() => {
          this.close()
          this.resetForm()
          this.$swal({
            icon: 'success',
            title: 'Trip booking added',
            text: 'Trip has been booked successfully',
            showCloseButton: true
          })
          this.$emit('route-added')
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: msg || 'An error occured',
            showCloseButton: true
          })
          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    },
    resetForm () {
      this.form = {
        route: null,
        pickup: null,
        destination: null,
        itineraryId: null,
        itinerary: null,
        startDate: '',
        source: 'main_balance',
        isSubscription: false,
        withLuggage: false,
        subscriptionDays: [],
        tripWeeks: 0,
        luggageQuantity: 0
      }
    },
    close (e) {
      const target = (e && e.target) || this.$refs.closeModalButton
      target.click()
      this.$emit('close')
      this.resetForm()
    }
  }
}
</script>
